import React from 'react';
import Layout from '../../layouts/Layout';
import { PageProps, graphql, useStaticQuery } from 'gatsby';
import JobTable from '../../components/JobTable/JobTable';

import heroImg from '../../images/DFH-Job-listing-hero.png';
import heroImgMobile from '../../images/DFH-Job-listing-hero-mobile.png';
import JobAlerts from '../../components/JobAlerts/JobAlerts';

const SearchPage = (props: PageProps) => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);

	return (
		<Layout
			title={'Dream Finders Homes: Careers'}
			desc={'Dream Finders Homes: Careers'}
			location={props.location}
		>
			<section
				className={
					'flex min-h-[310px] flex-col items-center justify-center gap-6 bg-cover bg-top md:min-h-[65vh] max-sm:hidden'
				}
				style={{ backgroundImage: `url('${heroImg}')` }}
			></section>
			<section
				className={
					'flex min-h-[310px] flex-col items-center justify-center gap-6 bg-cover bg-top md:min-h-[65vh] sm:hidden'
				}
				style={{ backgroundImage: `url('${heroImgMobile}')` }}
			></section>
			<section>
				<JobAlerts />
			</section>
			<JobTable data={data} />
		</Layout>
	);
};

export default SearchPage;
